@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

body {
  font-family: 'Kanit', sans-serif;
}

.nav_active {
  visibility: visible !important;
  opacity: 1 !important;
}
